import { createI18n } from "vue-i18n";

const messages = {
  en: {
    text: "EN",
    header: {
      aboutPageTitle: "About",
      contactPageTTitle: "Contact",
      homePageTitle: "Home",
      membersPageTitle: "Members",
      newsPageTitle: "News",
      studentsPageTitle: "Testimony",
      logoutButton: "Logout",
      partnersPageTTitle: "Partners"
    },
    footer: {
      contactMenuTitle: "Contact",
      aboutMenuTitle: "About Us",
      linkMenuTitle: "Useful Links",
      aboutDetailMenuTitle: "About Our Company",
    },
    aboutPage: {
      visionMisson: {
        title: "Vision & Mission",
        visionTitle: "Vision",
        missionTitle: "Mission",
        vision: "To bridge the gap between countries with underprivileged population and declining population through job creation, and economic empowerment",
        mission: "To empower professionals in becoming internationally capable and competitive human resource to improve their quality of life and contribute to their community and country",
        keyActivitiesText: "Key Activities",
        keyActivities1: "Providing technical, language and human skills training to create a skilled, competent, and mentally strong workforce.",
        keyActivities2: "Providing assistance and guidance through technology to ensure the wellbeing and individual growth of the workers during their working period.",
        keyActivities3: "Providing assistance and guidance through technology to improve their skillset and capabilities to ensure economic stability and fulfilled living post working period.",
        problemStatementText: "Problem Statement",
        problemStatement1: "The number of cases of prospective interns to Japan who were treated unfairly during the language training program even during their working period in Japan as well as the unskilled and unprepared interns in facing the work period in Japan became our main concern.",
        problemStatement2: "Besides, the amount of nursery graduates working in Indonesia, was unable to fully use their skill during work. Meanwhile, as the elderly population in Japan keeps on rising, the demand for caregiver worker from abroad including Indonesia, also rising.",
        problemStatement3: "Based on that concern, our philosophy is that the prospective intern students needs to be build so that they are not only competent in their expertise, but also have a good character and can compete globally.",
        sdgsText: "SDGs",
        sdgs: "Wiwitan also contributes on Sustainable Development Goals (SDGs) Indonesia."
      },
      ourServices: {
        title: "Our Services",
        caregiver6Months: {
          title: "Caregiver technical intern Japanese language training program for 6 months",
          point1: "Offline Japanese language training",
          point2: "N4 target - the 3rd month",
          point3: "N3 target - the 6th month",
          point4: "Caregiver technical intern Japanese language training",
          point5: "Training about Japanese culture",
          point6: "The program objectives is for technical internship candidates majoring in caregiver"
        },
        sswCaregiver6Months: {
          title: "SSW Caregiver Japanese language training program for 6 months",
          point1: "Offline Japanese language training",
          point2: "N4 target - the 3rd month",
          point3: "N3 target - the 6th month",
          point4: "Caregiver technical intern Japanese language training",
          point5: "Training about Japanese culture",
          point6: "The program objectives is for SSW candidates majoring in caregiver"
        },
        caregiver12Months: {
          title: "Caregiver technical intern hybrid Japanese language training program 12 months",
          point1: "Online (e-learning) and offline Japanese language training for 3 months",
          point2: "N4 target - the 6th month",
          point3: "N3 target - the 12th month",
          point4: "Offline learning will be held at the last 2 months for language training and pre-departure training",
          point5: "Caregiver technical Japanese language training",
          point6: "Japanese culture training",
          point7: "The program objectives is for technical intern candidates majoring in caregiver"
        },
        sswCaregiver12Months: {
          title: "SSW Caregiver hybrid Japanese language training for 12 months",
          point1: "Online (e-learning) and offline Japanese language training for 3 months",
          point2: "N4 target - the 6th month",
          point3: "N3 target - the 12th month",
          point4: "Offline learning will be held at the last 2 months for language training and pre-departure training",
          point5: "Caregiver technical Japanese language training",
          point6: "Japanese culture training",
          point7: "The program objectives is for SSW candidates majoring in caregiver"
        }
      },
      financialAssistance: {
        title: "Financial Assistance",
        text1: "Wiwitan is committed to be able to provide payment schemes and financial assistance to candidates who want to work in Japan to realize their dreams without involving debt.",
        text2: "At the moment, the company is still trying to provide various assistance schemes to candidates. The development carried out can affect changes in the assistance provided.",
        point1: "Installment facility: gradual payments that must be paid off before the 6th month training program is completed.",
        subPoint1: "(Program candidates have the opportunity to be able to apply for a payment plan to be able to complete payment of training fees before the training is completed)",
        point2: "BPR Credit facility: safe and will be guaranteed by Wiwitan, cooperate with Wiwitan's partner.",
        subPoint2: "(Program candidates get time assistance and a nominal reduction in training costs, where with this scheme the costs that need to be incurred by candidates are 15 million with monthly installments made from the 3rd month.)",
        point3: "Sponsorship facility: sponsorship from Wiwitan can be obtained with the terms and conditions applied",
        subPoint3: "(Program candidates have the opportunity to get a full scholarship for the training fees)"
      },
      workingInJapan: {
        title: "Working in Japan",
        point1: "Post-Arrival Training",
        subPoint1: "Technical intern program participant will participate in a training held by the Accepting Organization for 1 month before the intern work directly as a caregiver in Japan.",
        point2: "Self development and protection via application",
        subPoint2: "Through the use of technology, Wiwitan is committed to protect and develop the capabilities of its program participants. Workers can communicate work constraints, get personal and career consultations, get appraisals for performance, hone Japanese language skills, and improve interpersonal skills.",
        point3: "Assistance in Japan",
        subPoint3: "Wiwitan's representative in Japan and Accepting Organization are the figures who accompany workers in Japan. By utilizing the application, representatives can provide solutions to problems that may be encountered more thoroughly and effectively."
      },
      digitalEcosystem: {
        title: "Digital Ecosystem",
        text1: "Wiwitan uses technology to simplify the training process from registration to assignment in Japan",
        enrollmentText: "Enrollment",
        enrollmentPoint1: "Document selection: name, address, major, upload graduation certificate upload and essay to Google form.",
        enrollmentPoint2: "Language aptitude test: language aptitude test or Pimsleur is held to test technical intern candidates capability in learning new language.",
        enrollmentPoint3: "Character test: held to understand the intern candidates personality through MBTI & DISC (TBD) also a Q&A session.",
        trainingText: "Training",
        training6MonthsTrainingText: "6 Months Training",
        SixMonthsTrainingPoint1: "Take the Japanese N4 test in the 4th month",
        SixMonthsTrainingPoint2: "Interview/Matching",
        SixMonthsTrainingPoint3: "Contract sign",
        departurePreparation: "Departure Preparation",
        departurePreparationPoint1: "Part time in Wiwitan's partner senior living place",
        departurePreparationPoint2: "COE and Visa process",
        departurePreparationPoint3: "Departure to Japan",
        workingPeriodText: "Working Period",
        postArrivalText: "Post-Arrival",
        postArrivalPoint1: "1 month training in training center",
        postArrivalPoint2: "Start working",
        subWorkingPeriodText: "Working period",
        subWorkingPeriodPoint1: "Periodic assessment",
        advancedTraining: "Advanced training",
        advancedTrainingPoint1: "Soft Skills",
        advancedTrainingPoint2: "Financial management"
      },
      ourCommitment: {
        title: "Our Commitment",
        text1: "Wiwitan's commitment for users in Japan",
        subText1: "High skilled caregiver workers with high skilled professional capability and Japanese language capability for nursing home.",
        subText2: "Provide caregiver workers evaluation and monitoring system while working in nursing home to be able to assess their capability.",
        subText3: "Students characteristic data set that has been gathered from their activity in Indonesia."
      },
      partners: {
        title: "Partners",
        acceptingOrganinzationsText: "Accepting Organizations",
        AO: {
          name1: "Care Network Cooperative",
          name2: "TOA Care Welfare Cooperative Society",
          name3: "Shizuoka Kokusai Kaigo Under CUC",
          name4: "Comfort Working"
        },
        websites: {
          carenw: "https://care-nw.org/",
          comfortWorking: "https://comfortworking.jp/",
          toaCareWelfare: "https://ja-jp.facebook.com/toa.care.welfare.cooperative/",
          cuc: "https://www.cuc-jpn.com/",
          aceSystems: "https://www.acesystems.co.jp/"
        },
        investorsText: "Investors",
        investors: {
          aceSystems: "Ace Systems",
        },
        sponsorsText: "Sponsors",
        sponsorNames: {
          name1: "I Wayan Yustisia Semarariana"
        }
      }
    },
    teamMember: {
      teamMembers1: {
        name: "Aki Pratomodono",
        jabatan: "Founder & CEO"
      },
      teamMembers2: {
        name: "Ayako Kuriki",
        jabatan: "Associate Partner"
      },
      teamMembers3: {
        name: "Shohei Okada",
        jabatan: "Advisor"
      },
      teamMembers4: {
        name: "Lisa Aftita",
        jabatan: "Co-founder"
      },
      teamMembers5: {
        name: "Andy Laver",
        jabatan: "Advisor"
      },
      teamMembers6: {
        name: "Tia Fitria",
        jabatan: "Co-founder"
      }
    },
    contactUs: {
      title: "Contact Us",
      sponsorText: "Sponsor",
      sponsorSubText: "Join us to become the sponsor of our candidates and make their dream comes true!",
      sponsorLinkText: "Contact for Sponsor",
      impactText: "Enlarge the impact with us!",
      impactSubText: "Let's cooperate with us to provide a high capability caregiver and change their life simultaneously!",
      impactLinkText: "Contact for Investor/AO/Business Partner",
      studentRegistrationText: "Student registration",
      studentRegistrationSubText: "Change your life and seize the chance with us!",
      studentRegistrationSLinkText: "registration page"
    },
    loginPage: {
      username: "Username",
      password: "Password",
      backButton: "Back",
      loginButton: "Login",
    },
  },
  id: {
    text: "ID",
    header: {
      aboutPageTitle: "Tentang",
      contactPageTTitle: "Kontak",
      homePageTitle: "Beranda",
      membersPageTitle: "Anggota",
      newsPageTitle: "Berita",
      studentsPageTitle: "Testimoni",
      logoutButton: "Keluar",
      partnersPageTTitle: "Partner"
    },
    footer: {
      contactMenuTitle: "Kontak",
      aboutMenuTitle: "Tentang",
      linkMenuTitle: "Tautan",
      aboutDetailMenuTitle: "Tentang Perusahaan Kami",
    },
    aboutPage: {
      visionMisson: {
        title: "Visi & Misi",
        visionTitle: "Visi",
        missionTitle: "Misi",
        vision: "Untuk menjembatani kesenjangan antara negara-negara dengan populasi yang kurang mampu dan populasi yang menurun melalui penciptaan lapangan kerja, dan pemberdayaan ekonomi",
        mission: "Memberdayakan para profesional untuk menjadi sumber daya manusia yang mampu dan berdaya saing internasional untuk meningkatkan kualitas hidup mereka dan berkontribusi pada komunitas dan negara",
        keyActivitiesText: "Aktivitas yang Dijalankan",
        keyActivities1: "Memberikan pelatihan teknis, bahasa, dan keterampilan manusia untuk menciptakan tenaga kerja yang terampil, kompeten, dan bermental kuat.",
        keyActivities2: "Memberikan bantuan dan bimbingan melalui teknologi untuk menjamin kesejahteraan dan pertumbuhan individu pekerja selama masa kerjanya.",
        keyActivities3: "Memberikan bantuan dan bimbingan melalui teknologi untuk meningkatkan keterampilan dan kemampuan untuk memastikan stabilitas ekonomi dan memenuhi masa hidup pasca kerja.",
        problemStatementText: "Rumusan Masalah",
        problemStatement1: "Maraknya kasus calon peserta magang ke Jepang yang mendapatkan perlakukan tidak adil selama masa pelatihan bahasa bahkan selama masa kerja di Jepang serta ketidakterampilan dan ketidaksiapan peserta dalam menghadapi masa kerja di Jepang menjadi acuan utama kami.",
        problemStatement2: "Selain itu, banyaknya lulusan keperawatan yang bekerja di Indonesia, kurang dapat memakai skillnya dikehidupan kerja. Sementara populasi warga yang sudah lanjut usia terutama di Jepang, membuat mereka membutuhkan tenaga kerja keperawatan dari luar negeri, termasuk Indonesia.",
        problemStatement3: "Kami memiliki filosofi bahwa calon peserta magang harus dibentuk agar tidak hanya kompeten dalam keahliannya, akan tetapi juga memiliki karakter yang baik dan dapat berkompetisi secara global.",
        sdgsText: "SDGs",
        sdgs: "Wiwitan juga ikut berkontribusi dalam Sustainable Development Goals (SDGs) Indonesia."
      },
      ourServices: {
        title: "Pelayanan Kami",
        caregiver6Months: {
          title: "Program pelatihan Bahasa jepang magang teknis Kaigo 6 bulan",
          point1: "Pelatihan bahasa jepang offline",
          point2: "Target N4 - bulan ke 3",
          point3: "Target N3 - bulan ke 6",
          point4: "Pelatihan bahasa jepang teknis caregiver",
          point5: "Pelatihan mengenai budaya Jepang",
          point6: "Tujuan program untuk kandidat pemagang teknis jurusan caregiver"
        },
        sswCaregiver6Months: {
          title: "Program pelatihan bahasa jepang SSW Kaigo 6 bulan",
          point1: "Pelatihan bahasa jepang offline",
          point2: "Target N4 - bulan ke 3",
          point3: "Target N3 - bulan ke 6",
          point4: "Pelatihan bahasa jepang teknis caregiver",
          point5: "Pelatihan mengenai budaya Jepang",
          point6: "Tujuan program untuk kandidat SSW jurusan caregiver"
        },
        caregiver12Months: {
          title: "Program pelatihan Bahasa jepang Hybrid magang teknis Kaigo 12 bulan",
          point1: "Pelatihan bahasa jepang online (e-learning) dan offline 3 bulan",
          point2: "Target N4 - bulan ke 6",
          point3: "Target N3 - bulan ke 12",
          point4: "Pembelajaran offline digunakan untuk 2 bulan terakhir pelatihan bahasa + pelatihan pra keberangkatan",
          point5: "Pelatihan bahasa jepang teknis caregiver",
          point6: "Pelatihan mengenai budaya Jepang",
          point7: "Tujuan program untuk kandidat pemagang teknis jurusan caregiver"
        },
        sswCaregiver12Months: {
          title: "Program pelatihan Bahasa jepang Hybrid SSW Kaigo 12 bulan",
          point1: "Pelatihan bahasa jepang online (e-learning) dan offline 3 bulan",
          point2: "Target N4 - bulan ke 6",
          point3: "Target N3 - bulan ke 12",
          point4: "Pembelajaran offline digunakan untuk 2 bulan terakhir pelatihan bahasa + pelatihan pra keberangkatan",
          point5: "Pelatihan bahasa jepang teknis caregiver",
          point6: "Pelatihan mengenai budaya Jepang",
          point7: "Tujuan program untuk kandidat SSW jurusan caregiver"
        }
      },
      financialAssistance: {
        title: "Bantuan Finansial",
        text1: "Wiwitan berkomitmen untuk dapat memberikan skema pembayaran dan bantuan finansial kepada kandidat yang ingin bekerja di Jepang untuk mewujudkan mimpi mereka tanpa melibatkan hutang.",
        text2: "Saat ini perusahaan masih terus berusaha untuk memberikan skema bantuan yang beragam kepada kandidat. Pengembangan yang dilakukan dapat berpengaruh kepada perubahan bantuan yang diberikan.",
        point1: "Fasilitas cicilan: pembayaran bertahap yang harus lunas sebelum program 6 bulan selesai.",
        subPoint1: "(Kandidat program memiliki kesempatan untuk dapat mengajukan rencana pembayaran untuk dapat menyelesaikan pembayaran biaya pelatihan sebelum pelatihan selesai)",
        point2: "Fasilitas Kredit BPR : aman dan akan dijamin oleh Wiwitan bekerja sama dengan rekanan Wiwitan.",
        subPoint2: "(Kandidat program mendapatkan bantuan waktu dan pengurangan nominal biaya pelatihan, dimana dengan skema ini biaya yang perlu dikeluarkan oleh kandidat menjadi sebesar 15 juta dengan cicilan per bulan dilakukan dari bulan ke 3)",
        point3: "Fasilitas Sponsorship : sponsorship dari Wiwitan bisa didapatkan dengan syarat dan ketentuan yang berlaku",
        subPoint3: "(Kandidat program memiliki kesempatan untuk mendapatkan beasiswa untuk seluruh biaya pelatihan)"
      },
      workingInJapan: {
        title: "Selama Kerja di Jepang",
        point1: "Pelatihan pasca kedatangan",
        subPoint1: "Peserta program pemagangan teknis akan melakukan pelatihan selama 1 bulan yang dilaksanakan oleh AO sebelum pemagang terjun langsung sebagai caregiver di Jepang.",
        point2: "Perlindungan dan pengembangan diri via aplikasi",
        subPoint2: "Melalui penggunaan teknologi, wiwitan berkomitmen untuk melindungi dan mengembangkan kapabilitas peserta programnya. Pekerja dapat mengkomunikasikan kendala pekerjaan, mendapatkan konsultasi personal dan karir, mendapatkan penilaian untuk kinerja yang dilakukan, mengasah kemampuan bahasa Jepang, dan menambah kemampuan interpersonal.",
        point3: "Pendampingan di lapangan",
        subPoint3: "Perwakilan Wiwitan di Jepang dan AO menjadi sosok yang mendampingi para pekerja di Jepang. Dengan memanfaatkan aplikasi, para perwakilan dapat memberikan solusi terhadap masalah yang mungkin dihadapi dengan lebih menyeluruh dan efektif."
      },
      digitalEcosystem: {
        title: "Digital Ecosystem",
        text1: "Pemanfaatan teknologi digunakan oleh Wiwitan untuk mempermudah proses pelatihan dari registrasi hingga masa penugasan di Jepang.",
        enrollmentText: "Enrollment",
        enrollmentPoint1: "Seleksi dokumen: nama, alamat, jurusan, upload scan ijazah, serta essay ke Google form.",
        enrollmentPoint2: "Seleksi bakat Bahasa: tes bakat bahasa atau pimsleur yang bertujuan untuk mengukur kemampuan calon peserta dalam mempelajari bahasa asing.",
        enrollmentPoint3: "Tes karakter: dilakukan untuk mengetahui kepribadian calon peserta melalui MBTI & DISC (TBD) serta sesi Q&A.",
        trainingText: "Training",
        training6MonthsTrainingText: "Pelatihan 6 bulan",
        SixMonthsTrainingPoint1: "Mengikuti tes N4 dibulan ke-4",
        SixMonthsTrainingPoint2: "Interview/Matching",
        SixMonthsTrainingPoint3: "Ttd kontrak",
        departurePreparation: "Persiapan keberangkatan",
        departurePreparationPoint1: "Part time di perusahaan rekanan",
        departurePreparationPoint2: "Pembuatan COE and Visa",
        departurePreparationPoint3: "Keberangkatan ke Jepang",
        workingPeriodText: "Working Period",
        postArrivalText: "Pasca kedatangan",
        postArrivalPoint1: "Training 1 bulan di training center",
        postArrivalPoint2: "Masuk kerja",
        subWorkingPeriodText: "Masa kerja",
        subWorkingPeriodPoint1: "Periodic assessment",
        advancedTraining: "Advanced training",
        advancedTrainingPoint1: "Soft Skills",
        advancedTrainingPoint2: "Financial management"
      },
      ourCommitment: {
        title: "Komitmen Kami",
        text1: "Komitmen wiwitan untuk pengguna di Jepang",
        subText1: "Penyediaan tenaga kerja Kaigo dengan kapabilitas tinggi yang profesional dengan kemampuan bahasa jepang yang tinggi untuk nursing home.",
        subText2: "Penyediaan sistem evaluasi dan monitoring tenaga kerja Kaigo selama bekerja di nursing home untuk dapat menilai kemampuan mereka.",
        subText3: "Data set karakteristik anak yang sudah dikumpulkan dari berkegiatan di Indonesia."
      },
      partners: {
        title: "Partners",
        acceptingOrganinzationsText: "Accepting Organizations",
        AO: {
          name1: "Care Network Cooperative",
          name2: "TOA Care Welfare Cooperative Society",
          name3: "Shizuoka Kokusai Kaigo Under CUC",
          name4: "Comfort Working"
        },
        websites: {
          carenw: "https://care-nw.org/",
          comfortWorking: "https://comfortworking.jp/",
          toaCareWelfare: "https://ja-jp.facebook.com/toa.care.welfare.cooperative/",
          cuc: "https://www.cuc-jpn.com/",
          aceSystems: "https://www.acesystems.co.jp/"
        },
        investorsText: "Investors",
        sponsorsText: "Sponsors",
        sponsorNames: {
          name1: "I Wayan Yustisia Semarariana"
        }
      }
    },
    teamMember: {
      teamMembers1: {
        name: "Aki Pratomodono",
        jabatan: "Founder & CEO"
      },
      teamMembers2: {
        name: "Ayako Kuriki",
        jabatan: "Associate Partner"
      },
      teamMembers3: {
        name: "Shohei Okada",
        jabatan: "Advisor"
      },
      teamMembers4: {
        name: "Lisa Aftita",
        jabatan: "Co-founder"
      },
      teamMembers5: {
        name: "Andy Laver",
        jabatan: "Advisor"
      },
      teamMembers6: {
        name: "Tia Fitria",
        jabatan: "Co-founder"
      }
    },
    contactUs: {
      title: "Contact Us",
      sponsorText: "Sponsor",
      sponsorSubText: "Bergabung bersama kami menjadi sponsor untuk para kandidat kami dan wujudkan mimpi mereka!",
      sponsorLinkText: "Kontak untuk Sponsor",
      impactText: "Perbesar dampak bersama kami!",
      impactSubText: "Mari bekerja sama dengan kami untuk dapat menyediakan caregiver dengan kapabilitas tinggi dan mengubah hidup mereka di saat yang bersamaan!",
      impactLinkText: "Kontak untuk Investor/AO/Business Partner",
      studentRegistrationText: "Registrasi Peserta",
      studentRegistrationSubText: "Ubah hidupmu dan raih kesempatan bersama kami!",
      studentRegistrationSLinkText: "registration page"
    },
    loginPage: {
      username: "Nama Pengguna",
      password: "Kata Sandi",
      backButton: "Kembali",
      loginButton: "Masuk",
    },
  },
  jp: {
    text: "JP",
    header: {
      aboutPageTitle: "機関について",
      contactPageTTitle: "お問い合わせ",
      homePageTitle: "ホーム",
      membersPageTitle: "チームメンバー",
      newsPageTitle: "ニュース",
      studentsPageTitle: "プログラム参加者の声",
      logoutButton: "ログアウト",
      partnersPageTTitle: "パートナー"
    },
    footer: {
      contactMenuTitle: "連絡先",
      aboutMenuTitle: "機関",
      linkMenuTitle: "リンク",
      aboutDetailMenuTitle: "当社について",
    },
    aboutPage: {
      visionMisson: {
        title: "ビジョン・ミッション",
        visionTitle: "ビジョン",
        missionTitle: "ミッション",
        vision: "雇用創出と経済的エンパワーメントを通じて、恵まれていない人口の多い国と人口が減少している国の掛け橋となる",
        mission: "専門知識を持つ人々を国際的に活躍できる競争力のある人材に育て上げ、彼らの生活の質を向上させるだけでなく、地域社会や国に貢献できるようにする",
        keyActivitiesText: "主な事業活動",
        keyActivities1: "人間関係の構築に繋がる専門技術、言語、コミュニケーションスキルのトレーニングを提供し、能力が高く、精神的に強い人材を育成すること。",
        keyActivities2: "テクノロジーを通して支援やアドバイスを提供し、実習・就労期間中のプログラム参加者の生活の充実と成長を支えること。",
        keyActivities3: "テクノロジーを通して支援やアドバイスを提供し、プログラム参加者の実習・就労後の経済的な安定と充実した生活を維持するスキルと能力を向上させること。",
        problemStatementText: "ウィウィタンが解決を目指すこと",
        problemStatement1: "語学トレーニング期間中や日本での実習期間中に残念ながら不当な扱いを受けてしまった実習生の数や、まだ能力が未熟で準備が十分でないまま日本での実習に入ってしまった実習生の数の多さについて、ウィウィタンは問題意識を抱くようになりました。",
        problemStatement2: "そして、インドネシアで医療系の教育のバックグラウンドを持つ卒業生の多くは、金銭面の障壁により、残念ながら仕事で自分のスキルを十分に活かすことができていません。その一方で、高齢者人口の増加が続く日本では、インドネシアを含む海外からの介護人材を必要としています。",
        problemStatement3: "ウィウィタンは、これからの技能実習生や特定技能外国人等の介護人材は、十分な専門知識を有するだけでなく、優れた人格を持ち、グローバルに競争できるように育成されるべきであるという哲学を持っています。",
        sdgsText: "SDGs",
        sdgs: "ウィウィタンは、インドネシアのSustainable Development Goals (SDGs) の達成にも貢献しています。"
      },
      ourServices: {
        title: "サービス",
        caregiver6Months: {
          title: "介護技能実習生候補者向け6ヵ月の日本語トレーニングプログラム",
          point1: "対面での日本語トレーニング",
          point2: "3ヵ月目までの到達目標：日本語能力試験4級(JLPT N4)レベル",
          point3: "6ヵ月目までの到達目標：日本語能力試験3級(JLPT N3)レベル",
          point4: "介護専門用語のトレーニング",
          point5: "日本文化のトレーニング",
          point6: "※こちらは医療系の教育バックグラウンドを持つ技能実習生候補者向けのプログラムです。"
        },
        sswCaregiver6Months: {
          title: "介護特定技能外国人候補者向け6ヵ月の日本語トレーニングプログラム",
          point1: "対面での日本語トレーニング",
          point2: "3ヵ月目までの到達目標：日本語能力試験(JLPT N4)レベル",
          point3: "6ヵ月目までの到達目標：日本語能力試験(JLPT N3)レベル",
          point4: "介護専門用語のトレーニング",
          point5: "日本文化のトレーニング",
          point6: "※こちらは医療系の教育バックグラウンドを持つ特定技能外国人候補者向けのプログラムです。"
        },
        caregiver12Months: {
          title: "介護技能実習生候補者向け12ヵ月のハイブリッド日本語トレーニングプログラム",
          point1: "eラーニングを利用したオンラインでの日本語トレーニングに加え、3ヵ月間の対面での日本語トレーニング",
          point2: "6ヵ月目までの到達目標：日本語能力試験4級(JLPT N4)レベル",
          point3: "12ヵ月目までの到達目標：日本語能力試験3級(JLPT N3)レベル",
          point4: "対面での日本語学習は、語学トレーニングの最後の2か月と出発前のトレーニング時に行います。",
          point5: "介護専門用語のトレーニング",
          point6: "日本文化のトレーニング",
          point7: "※こちらは医療系の教育バックグラウンドを持つ技能実習生候補者向けのプログラムです。日本語トレーニングはオンラインと対面のハイブリッドで行います。"
        },
        sswCaregiver12Months: {
          title: "介護特定技能外国人候補者向け12ヵ月のハイブリッド日本語トレーニングプログラム",
          point1: "eラーニングを利用したオンラインでの日本語トレーニングに加え、3ヵ月間の対面での日本語トレーニング",
          point2: "6ヵ月目までの到達目標：日本語能力試験4級(JLPT N4)レベル",
          point3: "12ヵ月目までの到達目標：日本語能力試験3級(JLPT N3)レベル",
          point4: "対面での日本語学習は、語学トレーニングの最後の2か月と出発前のトレーニング時に行います",
          point5: "介護専門用語のトレーニング",
          point6: "日本文化のトレーニング",
          point7: "※こちらは医療系の教育バックグラウンドを持つ特定技能外国人候補者向けのプログラムです。日本語トレーニングは、オンラインと対面のハイブリッドで行います。"
        }
      },
      financialAssistance: {
        title: "資金援助",
        text1: "ウィウィタンは、プログラム参加者が金銭的な負担を負うことなく夢を実現できるよう、経済的支援と支払いの仕組みを提供して​​います。",
        text2: "現在、ウィウィタンはプログラム参加者により多様な支援を提供すべく、プランの検討を行っています。 今後、上記の資金援助プランは変更される可能性があります。",
        point1: "分割プラン：トレーニングプログラムの6ヵ月目が終わる前に、トレーニング費用の支払いが完了するプランです。",
        subPoint1: "（希望者は、トレーニング期間が終了する前にトレーニング費用の支払いを完了することができる、こちらのプランに申し込むことができます。）",
        point2: "提携先金融機関のローン利用プラン：ウィウィタンが提携している金融機関にて、ウィウィタンが保証人になることでローンを組むことができるプランです。",
        subPoint2: "（希望者は、支払いの猶予とトレーニング費用の名目上の割引を受けることができます。このプランでの返済はトレーニングの3ヵ月目からはじまり、総額1,500 万ルピアを毎月返済していくことになります。）",
        point3: "給付型奨学金プラン：ウィウィタンの提示する諸条件に該当する場合、返済不要の奨学金を受けることができます。",
        subPoint3: "（本プランでは、諸条件に該当するプログラム参加者は、すべてのトレーニング費用を奨学金として受けることができます。）"
      },
      workingInJapan: {
        title: "日本での実習・就労時",
        point1: "入国後トレーニング",
        subPoint1: "介護技能実習生は入国後、介護施設に配属される前に、1ヵ月間トレーニングセンターでトレーニングを受けます。",
        point2: "アプリを通した自己成長とサポートの提供",
        subPoint2: "ウィウィタンはテクノロジーを通じて、プログラム参加者の自己成長とサポートに取り組んでいます。 プログラム参加者は、アプリを利用して仕事や個人的な悩み事を相談し、個々に合ったアドバイスを受けられるだけでなく、業績評価を受け、日本語能力を磨き、対人スキルを向上させることができます。",
        point3: "現場での支援",
        subPoint3: "ウィウィタンの日本代表と監理団体は、日本にいるプログラム参加者をサポートしています。 アプリを利用することで、起こりうる問題に対して全般的かつ効果的な解決方法を提供することができます。"
      },
      digitalEcosystem: {
        title: "デジタルエコシステム",
        text1: "ウィウィタンはテクノロジーを利用して、入学手続きから日本での配属までのトレーニング プロセスをより簡潔にしています。         ",
        enrollmentText: "入学",
        enrollmentPoint1: "書類選考 ：オンライン上のクラウドにアップロードされた候補者の氏名、住所、専攻等のデータ、卒業証書のスキャンコピーと小論文の内容を元に選考を行います。",
        enrollmentPoint2: "言語能力診断：言語適性テストまたはピムスラーと呼ばれるテストを使用し、候補者の外国語学習能力を測定します。",
        enrollmentPoint3: "性格診断 ： MBTI(マイヤーズ・ブリッグスタイプ指標)、 TBDと呼ばれるDISC性格診断および面接を通して、候補者の性格診断を行います。",
        trainingText: "トレーニング",
        training6MonthsTrainingText: "6ヵ月のトレーニング",
        SixMonthsTrainingPoint1: "4ヵ月目に日本語能力試験4級(JLPT N4)レベルのテストを受験",
        SixMonthsTrainingPoint2: "面接・マッチング",
        SixMonthsTrainingPoint3: "契約書に署名",
        departurePreparation: "出発準備",
        departurePreparationPoint1: "ウィウィタンのパートナー先でのアルバイト",
        departurePreparationPoint2: "在留資格認定証明書とビサの発行",
        departurePreparationPoint3: "日本への出発",
        workingPeriodText: "実習・就労期間",
        postArrivalText: "入国後",
        postArrivalPoint1: "トレーニングセンターでの1ヵ月のトレーニング ※技能実習生のみ",
        postArrivalPoint2: "配属先での実習・就労の開始",
        subWorkingPeriodText: "実習・就労期間",
        subWorkingPeriodPoint1: "定期評価",
        advancedTraining: "上級トレーニング",
        advancedTrainingPoint1: "コミュニケーションスキル",
        advancedTrainingPoint2: "金銭管理"
      },
      ourCommitment: {
        title: "コミットメント",
        text1: "監理団体や受け入れ先企業へのウィウィタンのコミットメント",
        subText1: "介護施設向けに、高等な専門スキルと日本語能力を備えた介護人材を提供すること",
        subText2: "介護施設で実習・就労するプログラム参加者の能力評価システムと監視システムを提供すること",
        subText3: "インドネシアでのプログラム参加者の行動から収集した性格等のデータを共有すること"
      },
      partners: {
        title: "パートナー",
        acceptingOrganinzationsText: "監理団体",
        AO: {
          name1: "Care Network Cooperative",
          name2: "TOA Care Welfare Cooperative Society",
          name3: "Shizuoka Kokusai Kaigo Under CUC",
          name4: "Comfort Working"
        },
        websites: {
          carenw: "https://care-nw.org/",
          comfortWorking: "https://comfortworking.jp/",
          toaCareWelfare: "https://ja-jp.facebook.com/toa.care.welfare.cooperative/",
          cuc: "https://www.cuc-jpn.com/",
          aceSystems: "https://www.acesystems.co.jp/"
        },
        investorsText: "投資家",
        sponsorsText: "スポンサー",
        sponsorNames: {
          name1: "I Wayan Yustisia Semarariana"
        }
      }
    },
    teamMember: {
      teamMembers1: {
        name: "アキ・プラトモドノ",
        jabatan: "創設者および社長"
      },
      teamMembers2: {
        name: "栗城亜矢子",
        jabatan: "協力パートナー"
      },
      teamMembers3: {
        name: "岡田祥平",
        jabatan: "相談役"
      },
      teamMembers4: {
        name: "リサ・アフティータ",
        jabatan: "共同創設者"
      },
      teamMembers5: {
        name: "アンディ・レイバ",
        jabatan: "相談役"
      },
      teamMembers6: {
        name: "ティア・フィトリア",
        jabatan: "共同創設者"
      }
    },
    contactUs: {
      title: "お問い合わせ",
      sponsorText: "スポンサー",
      sponsorSubText: "スポンサーになり、ウィウィタンと一緒に候補者の夢を叶えましょう!",
      sponsorLinkText: "スポンサー用お問い合わせフォーム",
      impactText: "ウィウィタンと一緒に大きな影響を与えましょう！",
      impactSubText: "私たちと一緒に、高い能力を持つ介護人材を提供し、彼らの人生を変えることができるように協力しましょう！",
      impactLinkText: "投資家・監理団体・ビジネスパートナー用お問い合わせフォーム",
      studentRegistrationText: "入学手続き",
      studentRegistrationSubText: "ウィウィタンと一緒にチャンスを掴み、人生を変えましょう！",
      studentRegistrationSLinkText: "入学手続きページ"
    },
    loginPage: {
      username: "ユーザ名",
      password: "パスワード",
      backButton: "戻る",
      loginButton: "ログイン",
    },
  },
};

const i18n = new createI18n({
  globalInjection: true,
  locale: "en", // set locale
  fallbackLocale: "id", // set fallback locale
  messages, // set locale messages
});

export default i18n;
