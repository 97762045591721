<template>
    <footer class="site-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <h5 class="site-footer-title mb-3">Quick Links</h5>

                    <ul class="footer-menu">
                        <li class="footer-menu-item"><router-link class="nav-link click-scroll" to="/">{{ $t("header.homePageTitle") }}</router-link></li>
                        <li class="footer-menu-item"><router-link class="nav-link click-scroll" to="/about">{{ $t("header.aboutPageTitle") }}</router-link></li>
                        <li class="footer-menu-item"><router-link class="nav-link click-scroll" to="/members">{{ $t("header.membersPageTitle") }}</router-link></li>
                        <li class="footer-menu-item"><router-link class="nav-link click-scroll" to="/news">{{ $t("header.newsPageTitle") }}</router-link></li>
                        <li class="footer-menu-item"><router-link class="nav-link click-scroll" to="/students">{{ $t("header.studentsPageTitle") }}</router-link></li>
                        <li class="footer-menu-item"><router-link class="nav-link click-scroll" to="/contact">{{ $t("header.contactPageTTitle") }}</router-link></li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mx-auto">
                    <h5 class="site-footer-title mb-3">Contact Infomation</h5>

                    <p class="text-white d-flex mb-2">
                        <i class="bi-telephone me-2"></i>

                        <a href="tel: 813-2289-2158" class="site-footer-link">
                            +62 813-2289-2158
                        </a>
                    </p>

                    <p class="text-white d-flex">
                        <i class="bi-envelope me-2"></i>

                        <a href="mailto:info@yourgmail.com" class="site-footer-link">
                            hello@wiwitanbaru.com
                        </a>
                    </p>

                    <p class="text-white d-flex mt-3">
                        <i class="bi-geo-alt me-2"></i>
                        Goalpara Training Center : <a href="https://maps.app.goo.gl/mqyLD7gJ2jrQQuDT7">Jl. Goalpara no. 5 RT. 001 RW. 006 , Desa/Kec. Sukaraja, Kab. Sukabumi 43192</a>
                    </p>

                    <p class="text-white d-flex mt-3">
                        <i class="bi-geo-alt me-2"></i>
                        Kawitan Training Center: <a href="https://maps.app.goo.gl/LbqC3jYc8N6rbBR57">TEKAD Building Jl. Toyaning no. 18 Kedonganan, Kec. Kuta, Kab. Badung, Prov. Bali 80361</a>
                    </p>
                </div>
            </div>
        </div>

        <div class="site-footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-7 col-12">
                        <p class="copyright-text mb-0">Copyright © 2022 Wiwitan Baru. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default{
        name: "FooterComponent"
    };
</script>

<style lang="scss">
.site-footer-title {
    color: #ffffff;
}
.footer-menu-item {
    color: #ffffff;
}
</style>